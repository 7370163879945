var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('HeaderComp'), _c('c-box', {
    attrs: {
      "padding-top": "30px",
      "padding-bottom": "80px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1200px",
      "mx": "auto"
    }
  }, [_c('c-breadcrumb', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ol: {
          li: {
            a: {
              fontSize: '14px',
              fontFamily: 'Roboto'
            },
            span: {
              fontSize: '14px',
              fontFamily: 'Roboto'
            }
          }
        }
      },
      expression: "{\n          ol: {\n            li: {\n              a: {\n                fontSize: '14px',\n                fontFamily: 'Roboto',\n              },\n              span: {\n                fontSize: '14px',\n                fontFamily: 'Roboto',\n              },\n            },\n          },\n        }"
    }],
    attrs: {
      "margin-top": "20px",
      "margin-bottom": "20px"
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "href": "#",
      "color": "primary.400",
      "font-size": "14px",
      "font-fammily": "Roboto"
    }
  }, [_vm._v(" Beranda ")])], 1), _c('c-breadcrumb-item', {
    attrs: {
      "is-current-page": ""
    }
  }, [_c('c-breadcrumb-link', {
    attrs: {
      "font-size": "14px !important",
      "font-fammily": "Roboto !important"
    }
  }, [_vm._v(" Term & Condition ")])], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h2",
      "font-size": "36px",
      "font-weight": "600",
      "color": "black.900",
      "margin-bottom": "40px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.item.Title)
    }
  }), _c('c-box', {
    attrs: {
      "border-top": "1px solid #F2F2F2",
      "padding-top": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "line-height": "28px",
      "color": "darkGrey.900",
      "margin-bottom": "30px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.item.content)
    }
  })], 1), _vm._l(_vm.item.termsAndCondition, function (child, idx) {
    return _c('c-box', {
      key: idx
    }, [_c('c-heading', {
      attrs: {
        "as": "h3",
        "font-size": "18px",
        "line-height": "27px",
        "font-weight": "700",
        "color": "primary.400",
        "margin-bottom": "10px"
      },
      domProps: {
        "innerHTML": _vm._s(child.subTitle)
      }
    }), _c('c-text', {
      attrs: {
        "font-size": "16px",
        "line-height": "28px",
        "color": "darkGrey.900",
        "margin-bottom": "30px"
      },
      domProps: {
        "innerHTML": _vm._s(child.subContent)
      }
    })], 1);
  })], 2)], 1), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }